import { RouterProvider, createReactRouter } from "@tanstack/react-router";

import { rootRoute } from "./root-route";
import { homeRoute } from "./home";
import { practiceRoute } from "./practice";

const routeConfig = rootRoute.addChildren([homeRoute, practiceRoute]);

const router = createReactRouter({ routeConfig });

export function Router() {
	return <RouterProvider router={router} />;
}

declare module "@tanstack/react-router" {
	interface RegisterRouter {
		router: typeof router;
	}
}
