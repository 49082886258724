import { useState } from "react";

export default function useSoundEffect(url: string) {
	const [controls] = useState(() => {
		let audio = new Audio(url);
		audio.volume = 0.75;

		return {
			play() {
				audio.play();
			},
			pause() {
				audio.pause();
			},
		};
	});

	return controls;
}
