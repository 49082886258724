import { useEffect, useState } from "react";

import RefreshIcon from "~icons/ic/outline-refresh";
import UpdatingIcon from "~icons/ic/outline-update";

import { register } from "../service-worker-registration";
import { t } from "../translations";

import { navButtonStyle } from "./nav";

export function UpdateNotification() {
	let [updateAvailable, setUpdateAvailable] = useState<
		undefined | (() => void)
	>(undefined);
	let [installing, setInstalling] = useState(false);

	useEffect(() => {
		register({
			onUpdate(registration) {
				setUpdateAvailable(() => () => {
					let newSw = registration.waiting;
					if (!newSw) return;

					// Reload the page when the waiting service worker has been fully activated
					newSw.addEventListener("statechange", () => {
						if (newSw?.state === "activated")
							window.location.reload();
					});

					// Notify SW to stop waiting to take control
					registration.waiting?.postMessage({ type: "SKIP_WAITING" });
				});
			},
			onInstalling(registration) {
				setInstalling(true);
			},
			onSuccess() {
				setInstalling(false);
			},
		});
	}, []);

	if (updateAvailable) {
		return (
			<button
				type="button"
				onClick={updateAvailable}
				className={navButtonStyle + " primary no-button"}
			>
				<RefreshIcon />
				{/*<span className="icon">⟳</span>*/}
				<span className="label">{t.update}</span>
			</button>
		);
	}

	if (installing) {
		return (
			<div className={navButtonStyle + " disabled"}>
				<UpdatingIcon />
				<span className="label">{t.downloadingUpdate}</span>
			</div>
		);
	}

	return null;
}
