import { useEffect, useRef } from "react";
import { css, cx } from "@linaria/core";

export default function Points({ points }: { points: number }) {
	let containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (points <= 0) return;

		containerRef.current?.getAnimations().forEach((anim) => {
			anim.cancel();
			anim.play();
		});
	}, [points]);

	return (
		<div
			ref={containerRef}
			className={cx(container, points > 0 ? "bounce" : "")}
		>
			🟊
			<span>{points}</span>
		</div>
	);
}

let container = css`
	position: absolute;
	bottom: 0.5rem;
	right: 2rem;
	font-size: 6rem;
	color: #ffd700;
	line-height: 1;
	text-shadow: 0 10px 10px #0004;

	animation-duration: 1s;
	animation-fill-mode: both;

	&.bounce {
		animation-name: bounce;
	}

	span {
		position: absolute;
		font-size: 0.2em;
		font-weight: bold;
		color: #000a;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-shadow: none;
	}

	@keyframes bounce {
		0% {
			transform: translateY(0) scale(1, 1);
			text-shadow: 0 10px 10px #0004;
			animation-timing-function: ease-out;
		}
		25% {
			transform: translateY(-50px) scale(0.9, 1.1);
			text-shadow: 0 60px 20px #0003;
			animation-timing-function: ease-in;
		}
		55% {
			transform: translateY(0) scale(1.1, 0.9);
			text-shadow: 0 10px 10px #0004;
			animation-timing-function: ease-out;
		}
		75% {
			transform: translateY(-25px) scale(0.9, 1.1);
			text-shadow: 0 35px 15px #0003;
			animation-timing-function: ease-in;
		}
		100% {
			transform: translateY(0) scale(1, 1);
			text-shadow: 0 10px 10px #0004;
		}
	}
`;
