import { css } from "@linaria/core";
import { useLayoutEffect, useRef } from "react";

type Props = {
	words: string[];
	selected: number;
	onSelect: (i: number) => void;
};

export default function WordList({ words, onSelect, selected }: Props) {
	const selEl = useRef<HTMLLIElement>(null);

	useLayoutEffect(() => {
		selEl.current?.scrollIntoView({ block: "nearest" });
	}, [selected]);

	return (
		<ol className={list}>
			{words.map((word, i) => (
				<li
					key={i}
					className={i === selected ? "selected" : ""}
					ref={i === selected ? selEl : null}
				>
					<button
						type="button"
						className="text"
						onClick={(e) => {
							e.preventDefault();
							onSelect?.(i);
						}}
					>
						{word}
					</button>
				</li>
			))}
		</ol>
	);
}

let list = css`
	li {
		margin-bottom: 0.25em;
	}

	.selected button {
		font-weight: bold;
	}

	button {
		cursor: pointer;

		&:hover {
			color: #80f;
			//text-decoration: underline;
		}
	}
`;
