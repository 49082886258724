import { WordGroup } from "./types";

import family from "./family";
import food from "./food";
import fruits from "./fruits";

export const groups: WordGroup[] = [family, food, fruits];

export function getGroup(slug: string): WordGroup | undefined {
	return groups.find((g) => g.slug === slug);
}
