import { css } from "@linaria/core";

export let container = css`
	.main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1rem;
		height: 100%;

		> nav {
			margin-top: 1rem;
			display: flex;
			justify-content: space-between;

			button {
				font-size: 1.5rem;

				&.submit {
					min-width: 8em;
				}
			}
		}
	}

	.video {
		height: 100%;

		aspect-ratio: 9/16; // so that the size/width doesn't change when there is no video
		max-width: 40vw; // workaround for the video width being too wide when the window is not wide enough

		background: #ccc;

		video {
			background: #ccc;
			display: block;
			height: 100%;
			width: 100%;
		}
	}

	.word,
	.input > input {
		font-size: 3rem;
		text-transform: uppercase;
		padding: 0.05em 0.15em;
	}

	.word > em {
		color: green;
		font-style: normal;
	}

	.input > input {
		width: 100%;

		background: #fff;
		border: 0;
		border-bottom: 4px solid black;

		&:focus {
			box-shadow: 0 20px 15px -15px #80f;
			outline: none;
		}
	}
`;

export let image = css`
	width: 100%;
	flex-grow: 1;

	padding: 1rem;
	background: #fff;

	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 20rem;
	line-height: 1;
	color: #ddd;

	img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}
`;
