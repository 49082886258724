import { css, cx } from "@linaria/core";
import { Link } from "@tanstack/react-router";

import { groups } from "../../words";
import { practiceRoute } from "../practice";
import { rootRoute } from "../root-route";

export const homeRoute = rootRoute.createRoute({
	path: "/",
	component: Home,
});

export default function Home() {
	return (
		<div className={container}>
			<ul className={selector}>
				{groups.map((g) => (
					<li key={g.slug} className="group">
						<Link
							to={practiceRoute.id}
							params={{
								group: g.slug,
							}}
						>
							<div className={cx("thumb", g.thumbs && "multi")}>
								{g.thumbs ? (
									g.thumbs.map((src, i) => (
										<img key={i} src={src} alt="" />
									))
								) : (
									<img
										src={g.words?.[0].image}
										alt={g.words?.[0].word}
									/>
								)}
							</div>
							<div className="name">{g.name}</div>
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
}

let container = css`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

let selector = css`
	display: flex;
	gap: 1rem;

	list-style: none;
	padding: 0;

	> .group {
		img {
			width: 100%;
			display: block;
		}

		a {
			display: block;
			width: 10rem;
			padding: 0.5rem;

			border: 2px solid transparent;
			border-radius: 6px;

			background: #fff;

			color: #80f;
			text-decoration: none;

			transition: all calc(20s / 60);

			&:hover:not(:active) {
				transition-duration: calc(5s / 60);
				transition-timing-function: linear;

				border-color: #80f;
				margin-top: -10px;
				//box-shadow: 0 20px 15px -13px #80fb;
				box-shadow: 0 20px 25px -5px #80f6, 0 10px 10px -5px #80f6;
			}
			&:active {
				border-color: #80f;
			}
		}

		.thumb {
			border-radius: 3px;
			overflow: hidden;

			&.multi {
				display: flex;
				flex-wrap: wrap;
				gap: 3px;

				> img {
					width: calc(50% - 3px);
					aspect-ratio: 1;
					object-fit: cover;
					border-radius: 3px;
				}
			}
		}

		.name {
			padding-top: 0.25em;
			font-size: 1.5rem;
			text-align: center;
		}
	}
`;
