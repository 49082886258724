import { css } from "@linaria/core";
import { Outlet } from "@tanstack/react-router";

import "./global";

import Nav from "./nav";

export default function Layout() {
	return (
		<div className={container}>
			<Nav />
			<main>
				<Outlet />
			</main>
		</div>
	);
}

let container = css`
	height: 100%;
	width: 100%;

	display: flex;

	> :first-child {
		flex-shrink: 0;
	}

	> main {
		flex-grow: 1;
		position: relative;
	}
`;
