import { useEffect, useRef, useState } from "react";
import useSoundEffect from "../hooks/use-sound-effect";
import { t } from "../translations";
import * as style from "./style";

import sound from "./success.mp3";

export type WordProps = {
	word: string;
	image?: string;
	video?: string;
	onNext?: () => void;
	onPrev?: () => void;
	onSolve?: () => void;
	allowIncorrectLetters?: boolean;
};

export default function Word({
	word,
	image,
	video,
	onNext,
	onPrev,
	onSolve,
	allowIncorrectLetters,
}: WordProps) {
	let [input, setInput] = useState("");
	let [solved, setSolved] = useState(false);

	let inputRef = useRef<HTMLInputElement>(null);
	let videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		inputRef.current?.focus();
	});

	let { play: playSound } = useSoundEffect(sound);

	let [correctLetters, restLetters] = verifyInput(word, input);
	let allCorrect = correctLetters === word;

	useEffect(() => {
		if (allCorrect && !solved) {
			setSolved(true);
			onSolve?.();
			playSound();
			videoRef.current?.play();
		}
	}, [allCorrect, solved, onSolve, playSound]);

	useEffect(() => {
		function handler({ key }: KeyboardEvent) {
			if (key === "ArrowDown") onNext?.();
			if (key === "ArrowUp") onPrev?.();
		}

		window.addEventListener("keydown", handler);
		return () => {
			window.removeEventListener("keydown", handler);
		};
	}, [onNext, onPrev]);

	return (
		<form
			className={style.container}
			onSubmit={(e) => {
				e.preventDefault();
				onNext?.();
			}}
		>
			<div className="main">
				<div className={style.image}>
					{image ? <img src={image} alt={word} /> : "🖼"}
				</div>
				<div className="word">
					<em>{correctLetters}</em>
					{restLetters}
				</div>
				<div className="input">
					<input
						ref={inputRef}
						value={input}
						onChange={({ target }) =>
							setInput(
								allowIncorrectLetters
									? target.value
									: verifyInput(target.value, word)[0]
							)
						}
						maxLength={word.length}
						size={word.length}
					/>
				</div>

				<nav>
					<button type="button" className="outline" onClick={onPrev}>
						🡄
					</button>

					<button className="submit" disabled={!allCorrect}>
						{t.continue}
					</button>

					<button type="button" className="outline" onClick={onNext}>
						🡆
					</button>
				</nav>
			</div>

			<div className="video">
				<video ref={videoRef} controls autoPlay muted={false}>
					{video && <source src={video} type="video/mp4" />}
				</video>
			</div>
		</form>
	);
}

function verifyInput(
	word: string,
	input: string,
	requirePolishLetters?: boolean
) {
	let correct = "";
	let rest = "";

	for (let i = 0; i < word.length; i++) {
		if (
			i < input.length &&
			word[i].localeCompare(
				input[i],
				requirePolishLetters ? "pl" : "en",
				{ sensitivity: "base" }
			) === 0
		) {
			correct += word[i];
		} else {
			rest = word.substring(i);
			break;
		}
	}

	return [correct, rest] as const;
}
