import { useParams } from "@tanstack/react-router";
//import { z } from "zod";

import { rootRoute } from "../root-route";
import Trainer from "../../trainer";
import { getGroup } from "../../words";

//let searchSchema = z.object({ group: z.string() });

export const practiceRoute = rootRoute.createRoute({
	path: "practice/$group",
	//validateSearch: (s) => {
	//	//console.log("validateSearch", s);

	//	// Bug: getting empty search even tho query string has values and is received correctly in the component
	//	if (Object.keys(s).length === 0)
	//		return null as unknown as typeof searchSchema;

	//	return searchSchema.parse(s);
	//},
	component: Practice,
});

export default function Practice() {
	let { group: slug } = useParams();
	if (!slug) throw Error("Group not found");

	let group = getGroup(slug.toLowerCase());
	if (!group) throw Error("Group not found");

	return <Trainer words={group.words} />;
}
