import { WordGroup } from "../types";

import * as arbuz from "./arbuz";
import * as banan from "./banan";
import * as borowki from "./borowki";
import * as brzoskwinia from "./brzoskwinia";
import * as czeresnie from "./czeresnie";
import * as figa from "./figa";
import * as gruszka from "./gruszka";
import * as jablko from "./jablko";
import * as maliny from "./maliny";
import * as mandarynki from "./mandarynki";
import * as melon from "./melon";
import * as nektaryna from "./nektaryna";
import * as pomarancz from "./pomarancz";
import * as sliwka from "./sliwka";
import * as winogrona from "./winogrona";
import * as wisnie from "./wisnie";

import thumb1 from "./jablko/image.jpg?as=mini&imgmin";
import thumb2 from "./banan/image.jpg?as=mini&imgmin";
import thumb3 from "./brzoskwinia/image.jpg?as=mini&imgmin";
import thumb4 from "./arbuz/image.jpg?as=mini&imgmin";

const thumbs = [thumb1, thumb2, thumb3, thumb4];

export const words = [
	arbuz,
	banan,
	borowki,
	brzoskwinia,
	czeresnie,
	figa,
	gruszka,
	jablko,
	maliny,
	mandarynki,
	melon,
	nektaryna,
	pomarancz,
	sliwka,
	winogrona,
	wisnie,
];

export default {
	name: "Owoce",
	slug: "fruits",
	words,
	thumbs,
} as WordGroup;
