import { css } from "@linaria/core";
import preval from "preval.macro";
import pkg from "../../package.json";

export const version = pkg.version;
export const buildDate = preval`module.exports = new Date().toISOString().replace("T", " ").replace(/:\\d{2}\\.\\d+Z?/, "");`;

export function BuildInfo() {
	return (
		<div className={container}>
			<div className="ver">{version}</div>
			<div className="date">{buildDate}</div>
		</div>
	);
}

let container = css`
	color: #888;

	> .ver {
		font-weight: bold;
		font-size: 0.75em;
	}
	> .date {
		font-size: 0.5em;
	}
`;
