import { WordGroup } from "../types";

import * as karolina from "./karolina";
import * as asia from "./asia";
import * as janusz from "./janusz";
import * as kasia from "./kasia";
import * as andreas from "./andreas";
import * as mateusz from "./mateusz";
import * as precelek from "./precelek";
import * as marcin from "./marcin";
import * as madzia from "./madzia";
import * as brygida from "./brygida";

import thumb1 from "./karolina/image.jpg?as=mini&imgmin";
import thumb2 from "./asia/image.jpg?as=mini&imgmin";
import thumb3 from "./janusz/image.jpg?as=mini&imgmin";
import thumb4 from "./kasia/image.jpg?as=mini&imgmin";

const thumbs = [thumb1, thumb2, thumb3, thumb4];

export const words = [
	karolina,
	asia,
	kasia,
	janusz,
	precelek,
	mateusz,
	madzia,
	andreas,
	marcin,
	brygida,
];

export default {
	name: "Rodzina",
	slug: "family",
	words,
	thumbs,
} as WordGroup;
