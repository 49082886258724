import { useState } from "react";
import { css } from "@linaria/core";

import Word from "../word";
import WordList from "../word-list";
import Points from "../points";
import { Word as WordType } from "../words/types";

type Props = {
	words: WordType[];
};

export default function Trainer({ words }: Props) {
	let [wordIdx, setWordIdx] = useState(0);
	let [points, setPoints] = useState(0);

	let word = words[wordIdx];

	return (
		<div className={container}>
			<Word
				key={wordIdx}
				word={word.word}
				image={word.image}
				video={word.video}
				onNext={() => setWordIdx((wordIdx + 1) % words.length)}
				onPrev={() =>
					setWordIdx((wordIdx - 1 + words.length) % words.length)
				}
				onSolve={() => setPoints(points + 1)}
			/>
			<aside>
				<WordList
					words={words.map((w) => w.word)}
					onSelect={(i) => setWordIdx(i)}
					selected={wordIdx}
				/>
			</aside>
			<Points points={points} />
		</div>
	);
}

let container = css`
	height: 100%;
	width: 100%;

	--spacing: 1rem;

	display: grid;

	// prettier-ignore
	grid-template: ". word . . video . . list" 100% /
		/* space */ 2fr
		/* word  */ 35%
		/* space */ var(--spacing) 1fr
		/* video */ auto
		/* space */ var(--spacing) 1fr
		/* list  */ minmax(15%, min-content);

	padding-left: var(--spacing);

	> :first-child {
		display: contents;

		.main {
			grid-area: word;

			height: calc(100% - 2 * var(--spacing));
			align-self: center;
		}

		.video {
			grid-area: video;

			height: calc(100% - 2 * var(--spacing));
			align-self: center;
		}
	}

	> aside {
		grid-area: list;
		padding-right: var(--spacing);

		overflow-x: hidden;
		overflow-y: auto;
	}
`;
