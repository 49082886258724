import { css } from "@linaria/core";
import { Link } from "@tanstack/react-router";

import HomeIcon from "~icons/ic/outline-home";

import { t } from "../translations";

import { BuildInfo } from "./build-into";
import { UpdateNotification } from "./update-notification";

export default function Nav() {
	return (
		<div className={container}>
			<div className={title}>
				<div className="name">Slowka</div>
				<BuildInfo />
			</div>

			<nav>
				<Link to="/" className={navButtonStyle}>
					<HomeIcon />
					<span className="label">{t.home}</span>
				</Link>

				<UpdateNotification />
			</nav>
		</div>
	);
}

let container = css`
	width: 6rem;
	height: 100%;
	position: relative;
	padding: 1rem;
	padding-right: 0;

	background: linear-gradient(to right, #ccc 10%, #ddd 30%, transparent 70%);

	> nav {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
`;

let title = css`
	> .name {
		font-size: 22px;
		font-weight: bold;

		background: #80f;
		background: linear-gradient(to bottom right, #80f, #40f);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	> :not(.name) {
		margin: 0.25rem 0 1rem;
	}
`;

export let navButtonStyle = css`
	display: block;
	font-size: inherit;

	border: 2px solid transparent;
	border-radius: 6px;

	background: #fff;

	color: #80f;
	text-decoration: none;

	text-align: center;
	padding-bottom: 0.25em;

	transition: all calc(20s / 60);

	position: relative;
	top: 0;

	&.disabled {
		color: #888;
	}

	&:hover:not(:active):not(.disabled) {
		transition-duration: calc(3s / 60);
		transition-timing-function: linear;

		border-color: #80f;
		top: -4px;

		//box-shadow: 0 20px 15px -13px #80fb;
		box-shadow: 0 5px 10px -5px #80fb, 0 10px 10px -5px #80f6;
	}

	&:active:not(.disabled) {
		border-color: #80f;
	}

	&.primary {
		background: #80f;
		color: #fff;
	}

	> .icon {
		display: block;
		margin: 0 auto;
		font-size: 2em;
		line-height: 1;
	}

	> .label {
		display: block;
		font-size: 0.75em;
	}
`;
