import { WordGroup } from "../types";

import * as bulka from "./bulka";
import * as chleb from "./chleb";
import * as herbata from "./herbata";
import * as kawa from "./kawa";
import * as kielbasa from "./kielbasa";
import * as konserwa from "./konserwa";
import * as maslo from "./maslo";
import * as olej from "./olej";
import * as ryba from "./ryba";
import * as ser from "./ser";
import * as smalec from "./smalec";
import * as szynka from "./szynka";
import * as woda from "./woda";

import thumb1 from "./bulka/image.jpg?as=mini&imgmin";
import thumb2 from "./szynka/image.jpg?as=mini&imgmin";
import thumb3 from "./konserwa/image.jpg?as=mini&imgmin";
import thumb4 from "./herbata/image.jpg?as=mini&imgmin";

const thumbs = [thumb1, thumb2, thumb3, thumb4];

export const words = [
	bulka,
	chleb,
	herbata,
	kawa,
	kielbasa,
	konserwa,
	maslo,
	olej,
	ryba,
	ser,
	smalec,
	szynka,
	woda,
];

export default {
	name: "Jedzenie",
	slug: "food",
	words,
	thumbs,
} as WordGroup;
