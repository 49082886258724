import { css } from "@linaria/core";

export let primary = "#f08";
export let onPrimary = "#fff";

css`
	:global() {
		*,
		::before,
		::after {
			box-sizing: border-box;
		}

		body,
		html {
			font-family: sans-serif;
			background: #eee;
			margin: 0;
			height: 100%;
		}

		#root {
			height: 100%;
		}

		button:not(.no-button),
		.button {
			padding: 0.25em 0.75em;

			background: #80f;
			color: #fff;
			border: none;
			border-radius: 0.3em;

			&:hover:not(:disabled):not(:active):not(.text) {
				box-shadow: 0 5px 5px #0006;
				transform: translateY(-5px);
			}

			&:disabled {
				opacity: 0.3;
				filter: grayscale(1);
			}

			&.outline {
				border: 0.1em solid currentColor;
				background: transparent;
				color: #80f;
			}
		}

		button.text {
			display: inline;
			padding: 0;
			background: none;
			border: none;
			font-size: inherit;
			color: inherit;
		}
	}
`;
